<!--
  PACKAGE_NAME : src\pages\esp\system\site\list.vue
  FILE_NAME : site
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 사이트 설정 목록
-->
<template>
	<div class="content-themes">
		<div class="page-sub-box">
			<esp-dx-data-grid :data-grid="dataGrid" ref="siteGrid"></esp-dx-data-grid>
		</div>
	</div>
</template>

<script>
import { isSuccess } from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
	components: {EspDxDataGrid},
	props: {
		codeKey: {
			type: String,
			default: '',
		},
	},
	watch: {},
	data() {
		return {
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					viewFl: null,
				},
				paramsData: null,
			},
			page: {
				sizeList: [],
				size: null,
			},
			dataGrid: {
				refName: 'siteGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				repaintChangesOnly: false,
				hoverStateEnabled: true,
				keyExpr: 'id',
				dataSource: [],
				apiActionNm: {
					update: 'SITE_LIST_UPDATE',
					delete: 'SITE_LIST_DELETE',
				},
				showActionButtons: {
					select: true,
					copy: true,
					delete: true,
				},
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: true,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				// scrolling:{ // 미사용시 주석처리
				//     mode: 'infinite' //스크롤 모드 : ['infinite', 'standard', 'virtual']
				// },
				remoteOperations: {
					// 서버사이드 여부
					filtering: false,
					sorting: true,
					grouping: false,
					paging: true,
				},
				paging: {
					// scrolling 미사용시만 적용됨
					enabled: true,
					pageSize: 10, //page.size,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: true, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [], //페이지 사이즈 선택 박스
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
					allowDeleting: false,
					allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
					changes: [], //null값으로 두면 에러남
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          allowSelectAll: true, // 전체 선택 허용 여부
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          deferred: false, //scrolling infinite 시 복수 선택 에러 방지
        },
				columns: [
					{
						caption: 'ID',
						dataField: 'id',
						width: 80,
						alignment: 'center', // left center right
						visible: false,
						allowEditing: false,
						sortOrder: 'none', // acs desc none
						allowHeaderFiltering: false,
						fixed: false, // 컬럼 fix 시 사용
						fixedPosition: 'left', // left or right
					},
					{
						caption: '사이트',
						dataField: 'site',
						width: 110,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
					{
						caption: '사이트명',
						dataField: 'siteNm',
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
					{
						caption: 'CTI 구분',
						dataField: 'ctiType',
						width: 110,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
					{
						caption: 'CTI 버전',
						dataField: 'ctiVer',
						width: 110,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
					},
					{
						caption: 'CTI 연결 정보',
						dataField: 'ctiConnstr',
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
					{
						caption: 'ERS 스키마',
						dataField: 'ersSchema',
						width: 150,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
					{
						caption: '솔루션형식',
						dataField: 'solutionType',
						width: 120,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
					},
					{
						caption: 'psdualFl',
						dataField: 'psdualFl',
						width: 110,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
					},
					{
						caption: 'SORT',
						dataField: 'siteOrd',
						width: 110,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
					{
						caption: '사용여부',
						dataField: 'viewFl',
						width: 110,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						lookup: {
							dataSource: this.$_enums.common.stringViewFlag.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
						requiredRule: {
							message: '필수값 입니다.',
						},
					},
				],
			},
		};
	},
	computed: {},
	methods: {
		/** @description: 데이터 조회 메서드 */
		async selectDataList(sort = '+siteOrd') {
			let vm = this;

			vm.dataGrid.dataSource = new CustomStore({
				key: 'id',
				async load(loadOptions) {
					let params = vm.$_getDxDataGridParam(loadOptions);

					if (!params.sort) {
						params.sort = sort;
					}

					params = { ...params };

					const payload = {
						actionname: 'SITE_LIST_ALL',
						data: { params: params },
					};

					const res = await vm.CALL_API(payload);
					if (isSuccess(res)) {
						let rtnData = {
							data: res.data.data,
							totalCount: res.data.header.totalCount,
						};

						vm.$refs.siteGrid.totalCount = rtnData.totalCount;

						return rtnData;
					} else {
						return () => {
							throw 'Data Loading Error';
						};
					}
				},
			});
		},
		/** @description: 표시여부 출력 */
		viewFl(rowData) {
			return rowData.viewFl === 'Y';
		},
		/** @description: 라이프 사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.selectDataList();
		},
	},
	created() {},
	mounted() {
		this.mountedData();
	},
};
</script>

<style scoped></style>
